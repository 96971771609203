<template>
  <nav class="navbar navbar-expand-lg bg-primary">
    <div class="container-fluid px-4">
      <div>
        <menu-nav v-if="$route.name !== 'home'" />
      </div>

      <div class="navbar-brand">
        <img
          class="m-0 ms-3"
          src="../../assets/brand-white.svg"
          height="40"
          alt="MDB Logo"
          loading="lazy"
        />
      </div>
      <div class="">
        <div class="btn-group shadow-none cursor-pointer">
          <i @click="logout()" class="fa fa-sign-out ms-3 text-white fs-5"> </i>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import MenuNav from "./MenuNav.vue";
export default {
  components: { MenuNav },
  name: "NavbarItem",
  data() {
    return {
      role: this.$store.state.user?.role,
      routes: [
        {
          title: "Home",
          path: "/home",
          role: [this.$store.state.user?.role],
        },
        {
          title: "Hodimlar",
          path: "/users",
          role: [this.$store.state.user?.role],
        },
        {
          title: "Mijozlar",
          path: "/clients",
          role: [this.$store.state.user?.role],
        },
        {
          title: "Sozlamalar",
          path: "/settings",
          role: [this.$store.state.user?.role],
        },
        {
          title: "Diagnostika",
          path: "/diagnostic",
          role: [this.$store.state.user?.role],
        },
      ],
    };
  },
  methods: {
    logout() {
      localStorage.clear();
      window.location = "/";
    },
    toggle_menu() {
      this.$store.dispatch("toggleNav", true);
    },
  },
};
</script>
