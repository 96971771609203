<template>
  <div class="content login">
    <div class="container">
      <div class="row justify-content-center">
        <div
          v-if="false"
          class="col-md-6 col-lg-6 col-xl-6 col-xxl-6 d-none d-sm-none d-md-none d-lg-block d-flex d-xl-block d-xxl-block justify-content-center"
        >
          <img
            src=""
            draggable="false"
            alt="Image"
            class="img-fluid"
            style="display: block; margin: auto"
            loop="1"
          />
        </div>
        <div class="col-md-12 col-lg-6 col-xl-6 col-xxl-6 col-sm-12 contents">
          <div class="row justify-content-center">
            <div class="col-md-8">
              <div class="mb-4 text-center">
                <img src="../../assets/brand-primary.svg" width="200" alt="" />
                <h3>Xush kelibsiz !</h3>
                <p class="mb-4">Kirish uchun ushbu formani to'ldiring.</p>
              </div>
              <form @submit.prevent="signin">
                <div class="form-group first field--not-empty">
                  <label for="username">Login</label>
                  <div class="input-group flex-nowrap">
                    <input
                      type="text"
                      class="form-control"
                      id="username"
                      placeholder="Login"
                      v-model="data.username"
                      autocomplete="off"
                      required
                    />
                    <div class="input-group-text">
                      <i class="fa-solid fa-at"></i>
                    </div>
                  </div>
                </div>
                <div class="form-group last mb-4 field--not-empty">
                  <label for="password">Parol</label>
                  <div class="input-group flex-nowrap">
                    <input
                      :type="type ? 'password' : 'text'"
                      class="form-control"
                      id="password"
                      placeholder="Parol"
                      v-model="data.password"
                      autocomplete="off"
                      required
                    />
                    <div class="input-group-text" @click="type = !type">
                      <i :class="`fa-regular fa-eye${type ? '-slash' : ''}`" />
                    </div>
                  </div>
                </div>
                <button
                  type="submit"
                  id="login_button"
                  class="btn btn-block text-white btn-primary"
                  :disabled="!data.username || !data.password"
                >
                  <i class="fa-solid fa-arrow-right-to-bracket"></i>
                  Kirish
                  <div
                    class="spinner-border spinner-border-sm mx-1"
                    role="status"
                    v-if="loading"
                  >
                    <span class="visually-hidden">Loading...</span>
                  </div>
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as api from "@/components/Utils/api";

export default {
  name: "Login",
  data() {
    return {
      data: {
        username: "",
        password: "",
      },
      type: true,
      loading: false,
      icon: "fa-regular fa-eye",
    };
  },
  methods: {
    signin() {
      this.loading = true;
      document.getElementById("login_button").disabled = true;
      api.token(this.data).then((res) => {
        this.requirements(res);
      });
      this.loading = false;
      document.getElementById("login_button").disabled = false;
    },
    requirements(e) {
      // if (e.role == "customer") {
      //   window.location = `/diagnostika?id=${e.id}&name=${
      //     e.name ? e.name : ""
      //   }`;
      // } else if (e.role == "customer_worker") {
      //   window.location = `/shartnoma?id=${e.id}&name=${e.name ? e.name : ""}`;
      // } else if (e.role == "admin_worker") {
      //   window.location = `/diagnostic-users?id=${e.id}&name=${
      //     e.name ? e.name : ""
      //   }`;
      // } else {
      //   window.location = `/diagnostic-users`;
      // }
      window.location = `/home`;
      this.$store.dispatch("setUser", e);
    },
  },
  mounted() {
    document.querySelector("[type=text]").focus();
  },
  created() {
    localStorage.clear();
  },
};
</script>

<style lang="scss" scoped>
.content {
  overflow-y: auto;
  height: 100vh;
  user-select: none;
  .container {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .row {
    margin: 4rem 0;
  }
  .input-group-text {
    width: 45px;
    background: #fff;
    cursor: pointer;
  }
}
@media (max-width: 768px) {
  .contents .row {
    margin: 0 0;
  }
}
</style>
