<script>
import * as api from "@/components/Utils/api";

export default {
  name: "DataDropdown",
  props: {
    modelValue: null,
    data: Array,
    type: String,
    property: String,
    property2: String,
    all: Boolean,
    params: Object,
    disabled: Boolean,
    multiple: Boolean,
  },
  emits: ["update:modelValue", "change"],
  data() {
    return {
      id: Date.now(),
      search: "",
      list: [],
      current_page: 1,
      pages: 1,
      lorem: null,
    };
  },
  computed: {
    request() {
      const type = this.$props.type;
      switch (type) {
        case "user":
          return "users";
        case "customer":
          return "customers";
        case "market":
          return "get_markets";
        case "currency":
          return "get_currencies";
        case "category":
          return "get_categories";
        case "product_type":
          return "get_product_types";
        case "product_type_for_discount":
          return "get_product_types_for_discount";
        case "cell":
          return "get_cells";
        case "product":
          return "get_products";
        case "region":
          return "get_mintaqalar";
        default:
          break;
      }
    },
    param() {
      const param = this.$props.params;
      const category_id = param?.category_id || 0;
      const mintaqa_id = param?.mintaqa_id || 0;
      const role = param?.role || "";

      return {
        category_id: category_id,
        mintaqa_id: mintaqa_id,
        search: this.search || param?.search,
        role: role,
        page: this.current_page,
        limit: 20,
        status: true,
        id: 0,
      };
    },
    searchable() {
      if (
        [
          "customer",
          "product_type",
          "cell",
          "product",
          "region",
          "product_type_for_discount",
        ].includes(this.$props.type)
      )
        return true;
      else return false;
    },
    title() {
      const type = this.$props.type;
      switch (type) {
        case "user":
          return "Hodim";
        case "customer":
          return "Mijoz";
        case "market":
          return "Ta'minotchi";
        case "currency":
          return "Valyuta";
        case "category":
          return "Kategoriya";
        case "product_type":
          return "Mahsulot turi";
        case "product_type_for_discount":
          return "Brendlar";
        case "cell":
          return "Polka";
        case "expense":
          return "Chiqim turlari";
        case "product":
          return "Mahsulotlar";
        case "region":
          return "Mintaqalar";
        default:
          break;
      }
    },
    text() {
      if (!this.$props.multiple)
        if (this.$props.modelValue)
          return this.$props.property2
            ? this.$props.modelValue[this.$props.property] +
                " - " +
                this.$props.modelValue[this.$props.property2]
            : this.$props.modelValue?.[this.$props.property];
        else return this.title;
      else return this.title;
    },
  },
  // created() {
  //   this.list = [];
  //   if (!this.data) this.get();
  // },
  methods: {
    get() {
      api[this.request](this.param).then((res) => {
        this.list = res.data;
        this.current_page = res.data.current_page;
        this.pages = res.data.pages;
      });
    },
    scroll(event) {
      const element = event.target;
      if (element.scrollTop + element.clientHeight >= element.scrollHeight) {
        if (this.current_page < this.pages - 1) {
          this.current_page = this.current_page + 1;
          this.get();
        }
      }
    },
    update(data, action) {
      let value = null;
      if (this.$props.multiple) {
        let array = this.$props.modelValue;
        if (action) array.splice(data, 1);
        else {
          if (!this.selected(data)) array.push(data);
        }
        value = array;
      } else value = data;
      this.$emit("update:modelValue", value);
      this.$emit("change", value);
    },
    selected(data) {
      const array = this.$props.modelValue;
      if (array?.length) {
        const founded = array.find((item) => {
          return typeof item == "object" ? item?.id == data?.id : item == data;
        });
        if (founded) return true;
      }
    },
  },
};
</script>

<template>
  <div class="dropdown" :id="type + id">
    <button
      class="btn btn-secondary dropdown-toggle"
      type="button"
      id="dropdownMenuButton1"
      data-mdb-toggle="dropdown"
      aria-expanded="false"
      :disabled="disabled"
      @click="
        current_page = 1;
        get();
      "
    >
      {{ text }}
    </button>

    <div
      class="table-responsive w-100 dropdown-menu"
      aria-labelledby="dropdownMenuButton1"
      @scroll="scroll($event)"
    >
      <input
        type="search"
        class="form-control form-control-sm mb-1"
        placeholder="qidiruv:"
        v-model="search"
        @keyup="
          list = [];
          current_page = 1;
          get();
        "
        v-if="searchable"
      />
      <ul class="list">
        <!-- <li v-if="all" @click="update(null)">Hammasi</li> -->
        <li
          v-for="item in list"
          :key="item"
          @click="update(item)"
          :class="{ selected: selected(item) }"
        >
          {{ item?.name }}
        </li>
      </ul>
    </div>
  </div>
</template>

<style scoped>
button {
  width: 100%;
  background-color: whitesmoke;
}

.table-responsive {
  padding: 5px 0;
  max-height: 25vh;
  scrollbar-gutter: stable both-edges;
}

input {
  border-radius: 10px;
}

ul {
  margin: 0;
  padding: 0;
  width: 100%;
  border: thin solid transparent;
  border-radius: 10px;
  list-style: none;
}

ul.list {
  border-color: lightgray;
}

li {
  padding: 5px;
}

li:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}

li:last-child {
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
}

ul.list li {
  border-bottom: thin solid lightgray;
  cursor: pointer;
}

ul.list li:hover {
  background-color: whitesmoke;
}

ul.list li:last-child {
  border: none;
}

.values {
  padding: 5px;
  display: flex;
  flex-wrap: wrap;
}

.value {
  padding: 5px;
  background-color: whitesmoke;
  border: thin solid gray;
  border-radius: 10px;
  font-size: small;
}

.selected {
  box-shadow: inset 0 0 5px 1px rgb(0, 0, 255, 0.5);
}

.v-close {
  width: min-content;
  height: min-content;
  padding: 0;
  color: black;
  border-color: transparent;
  background-color: transparent;
  font-size: small;
}
</style>
