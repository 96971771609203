<template></template>

<script>
import store from "../../store/index";

export default {
  name: "notification",
  data() {
    return {
      socket: new WebSocket(
        `wss://pcg.api-crud.uz/ws/notification?token=${store.state.user?.access_token}`
      ),
      data: {
        title: "Yangi topshiriq",
        body: "Hurmatli foydalanuvchi sizga ID 14 - buyurtma biriktirildi",
        user_id: 99,
      },
    };
  },
  computed: {
    support() {
      if ("Notification" in window) return true;
      else return false;
    },
    permission() {
      return Notification.requestPermission();
    },
  },
  created() {
    this.socket.onopen = (event) => {
      console.log(event);
    };
    this.socket.onmessage = (event) => {
      console.log("mess");
      this.data = event.data;
      this.checkSupport();
    };
    this.socket.onclose = () => {
      this.socket = new WebSocket(
        `wss://pcg.api-crud.uz/ws/notification?token=${store.state.user?.access_token}`
      );
    };
  },
  methods: {
    checkSupport() {
      if (this.support) this.checkPermission();
      // swal({
      //   icon: "error",
      //   title: "Ushbu brauzer bildirishnomani qo'llab-quvvatlamaydi !",
      //   text: " ",
      //   closeOnEsc: false,
      //   closeOnClickOutside: false,
      //   buttons: false,
      //   timer: 3000,
      // });
      else console.log("errr");
    },
    checkPermission() {
      this.permission.then((result) => {
        if (result == "denied") console.log("errr");
        //   swal({
        //     icon: "error",
        //     title: "Bildirishnomaga ruxsat berilmagan !",
        //     text: " ",
        //     closeOnEsc: false,
        //     closeOnClickOutside: false,
        //     buttons: false,
        //     timer: 3000,
        //   });
        this.sendNotification();
      });
    },
    sendNotification() {
      const data = this.data;
      new Notification(data.title, {
        body: data.body,
      }).onclick = () => {
        window.open(`http://localhost:8080/${this.url(data.title)}`);
      };
    },
    url(title) {
      if (title == "Task") return "shartnoma";
      else if (title == "Yangi topshiriq") return "pre-orders";
      else if (title == "Transfer") return "transfer";
      else return "";
    },
  },
};
</script>
