import { createStore } from "vuex";
let loading_index = 0;

export default createStore({
  state: {
    loading: false,
    user: JSON.parse(localStorage["user"] || null),
    hudud: JSON.parse(localStorage["hudud"] || null),
    show_nav: false,
  },
  mutations: {
    setloading(state, loading) {
      if (loading) {
        state.loading = loading;
        loading_index++;
      } else {
        loading_index--;
        if (!loading_index) state.loading = loading;
      }
    },
    setUser(state, user) {
      localStorage["user"] = JSON.stringify(user);
      state.user = user;
    },
    setHudud(state, hudud) {
      localStorage["hudud"] = JSON.stringify(hudud);
      state.hudud = hudud;
    },
    toggleNav(state, nav) {
      state.show_nav = nav;
    },
  },
  actions: {
    setloading(ctx, loading) {
      ctx.commit("setloading", loading);
    },
    showSidebar(ctx, showSidebar) {
      ctx.commit("sitebarActive", showSidebar);
    },
    setUser(ctx, user) {
      ctx.commit("setUser", user);
    },
    setHudud(ctx, hudud) {
      ctx.commit("setHudud", hudud);
    },
    toggleNav(ctx, nav) {
      ctx.commit("toggleNav", nav);
    },
  },
  getters: {
    loading(state) {
      return state.loading;
    },
    user(state) {
      return state.user;
    },
    hudud(state) {
      return state.hudud;
    },
    show_nav(state) {
      return state.show_nav;
    },
  },
});
