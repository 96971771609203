import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store/index";
import modal from "./components/Modal/Modal.vue";
import DataDropdown from "./components/Dropdown/DataDropdown.vue";
import { NumbersOnly, clear_input_file } from "./components/Utils/api";
import Swal from "sweetalert2";
import util from "./components/Utils/util"
const app = createApp(App);

app.config.globalProperties.$numbersonly = NumbersOnly;
app.config.globalProperties.$util = util;
app.config.globalProperties.$swal = Swal;
app.config.globalProperties.$clear_input_file = clear_input_file;

app.use(router);
app.use(store);
app.component("modal", modal);
app.component("DataDropdown", DataDropdown);
app.mount("#app");
