<template>
  <button
    class="btn float-start shadow-none toggle-menu p-0 text-white"
    @click="$store.dispatch('toggleNav', true)"
  >
    <i class="fa fa-bars fs-5" />
  </button>
  <div class="nav-menu row" :class="{ open: show_nav }">
    <div class="col-12 mb-3 d-flex justify-content-center">
      <img
        src="../../assets/brand-primary.svg"
        width="400"
        alt="brand"
        class="brand-pcg"
      />
    </div>
    <div
      class="col-6 col-sm-6 col-md-6 col-lg-4"
      v-for="item in Rolerouters"
      :key="item"
    >
      <router-link
        :to="item.route"
        class="nav-link"
        :class="{ active: $route.path == item.route }"
      >
        <i :class="item.icon"></i>
        {{ item.title }}
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "nav-menu",
  data() {
    return {
      active: 0,
      routes: [
        // { route: "home", title: "Asosiy", icon: "fa-solid fa-house-chimney" },
        // {
        //   route: "plans",
        //   title: "Rejalashtirish",
        //   icon: "fa-solid fa-calendar",
        // },
        // admin
        // admin_worker
        // customer
        // customer_worker
        {
          route: ["customer_worker", "customer"].includes(
            this.$store.getters.user.role
          )
            ? "diagnostika"
            : "diagnostic-users",
          title: "Diagnostika",
          icon: "fa-solid fa-chart-simple",
          role: ["admin", "admin_worker", "customer", "customer_worker"],
        },
        {
          route: `users`,
          title: "Hodimlar",
          icon: "fa-solid fa-people-group",
          role: ["admin"],
        },
        {
          route: `customer-user`,
          title: "Hodimlar",
          icon: "fa-solid fa-people-group",
          role: ["customer"],
        },
        {
          route: "clients",
          title: "Mijozlar",
          icon: "fa-solid fa-users",
          role: ["admin", "admin_worker"],
        },

        {
          route: "archive",
          title: "Arxiv",
          icon: "fa-solid fa-history",
          role: ["admin", "admin_worker"],
        },
        {
          route: "archive-user",
          title: "Arxivlar",
          icon: "fa-solid fa-history",
          role: ["customer"],
        },
        {
          route: "shartnoma",
          title: "Shartnomalar",
          icon: "fa-solid fa-pen-fancy",
          role: ["admin", "admin_worker", "customer", "customer_worker"],
        },
        {
          route: "new-clients",
          title: "Ariza topshirgan mijozlar",
          icon: "fa-solid fa-users",
          role: ["admin"],
        },
        {
          route: "settings",
          title: "Sozlamalar",
          icon: "fa-solid fa-gear",
          role: ["admin"],
        },
      ],
    };
  },
  methods: {
    getRoute() {
      const routeArr = this.$route.path.split("/");
      return routeArr[1];
    },
  },
  created() {
    window.onclick = (event) => {
      if (!event.target.closest(".toggle-menu"))
        this.$store.dispatch("toggleNav", false);
    };
  },
  computed: {
    show_nav() {
      return this.$store.getters.show_nav;
    },
    current_user() {
      return this.$store.getters.user;
    },
    Rolerouters() {
      const role = this.current_user?.role;
      return this.routes.filter((item) => {
        return item.role.includes(role);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.nav-menu {
  position: fixed;
  top: 50%;
  left: 5%;
  right: 5%;
  padding: 3rem;
  max-height: 90%;
  background-color: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(5px);
  border: 1.8px solid #3b71ca;
  border-radius: 10px;
  translate: 0 -50%;
  transform: scale(0);
  transition: all 0.25s;
  overflow: auto;
  z-index: 5;
  &.open {
    transform: scale(1);
    // translate: 0;
  }

  .col-6 {
    padding: 12px 12px;
    text-decoration: none;
    .nav-link {
      &.active {
        background-color: #3b71ca;
        color: white;
        border: 1px solid #3b71ca;
      }
    }

    &:hover {
      .nav-link {
        border: 1.5px solid #3b71ca;
      }
    }
    .nav-link {
      padding: 40px 0px;
      display: flex;
      place-content: center;
      place-items: center;
      background-color: transparent;
      color: #3b71ca;
      border: 1.5px solid #989898;
      border-radius: 10px;
      font-size: 15px;
      font-weight: 500;
      gap: 5px;

      .icon {
        width: 25px;
        height: 25px;
        background-color: black;
      }
    }
  }
}

@media (max-width: 968px) {
  .nav-menu {
    padding: 2rem;
    .nav-link {
      padding: 30px 20px;
    }
  }
}

@media (max-width: 662px) {
  .nav-menu {
    padding: 1.5rem;
    .brand-pcg {
      width: 250px;
    }
    .nav-link {
      padding: 30px 15px;
    }
  }
}
</style>
