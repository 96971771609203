<template>
  <h2 class="page-loader">
    <div id="loader"></div>
  </h2>
</template>

<script>
export default {
  name: "isloading",
};
</script>

<style scoped>
.page-loader {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgb(0, 0, 0, 0.3);
  z-index: 10000;
  cursor: progress;
}

#loader {
  position: absolute;
  z-index: 1;
  width: 130px;
  height: 130px;
  border: 10px double rgb(0, 0, 0, 0.7);
  border-block: 10px solid rgb(0, 0, 0, 0.7);
  border-radius: 50%;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
</style>
