import { createRouter, createWebHistory } from "vue-router";
import store from "../store/index";

const access_token = localStorage["access_token"];
const role = localStorage["role"];

const requireAuth = (to, from, next) => {
  let access_token = store.state.user?.access_token;
  if (!access_token) {
    next({ name: "login" });
    return;
  }
  next();
  return;
};

let routes = [
  {
    path: "/:catchAll(.*)",
    name: "notFound",
    meta: { title: "Sahifa Topilmadi" },
    component: () => import("../components/Utils/NotFound.vue"),
  },
  {
    path: "/",
    name: "login",
    meta: { title: "Login" },
    component: () => import("../views/Login/Login.vue"),
  },
  {
    path: "/home",
    name: "home",
    beforeEnter: requireAuth,
    meta: { title: "Home" },
    component: () => import("../views/Home.vue"),
  },
  {
    path: "/users",
    name: "users",
    beforeEnter: requireAuth,
    meta: { title: "Hodimlar" },
    component: () => import("../views/Users/Users.vue"),
  },
  {
    path: "/customer-user",
    name: "customer-user",
    beforeEnter: requireAuth,
    meta: { title: "Mijoz hodimi" },
    component: () => import("../views/Users/CustomerUser.vue"),
  },
  {
    path: "/clients",
    name: "clients",
    beforeEnter: requireAuth,
    meta: { title: "Mijozlar" },
    component: () => import("../views/Clients/Clients.vue"),
  },
  {
    path: "/client",
    name: "client",
    beforeEnter: requireAuth,
    meta: { title: "Mijoz" },
    component: () => import("../views/Clients/Client.vue"),
  },
  {
    path: "/new-clients",
    name: "newClients",
    beforeEnter: requireAuth,
    meta: { title: "Mijozlar" },
    component: () => import("../views/Clients/NewCustomers.vue"),
  },

  {
    path: "/shartnoma",
    name: "shartnoma",
    beforeEnter: requireAuth,
    meta: { title: "Shartnoma" },
    component: () => import("../views/Shartnoma/Shartnoma.vue"),
  },
  {
    path: "/money",
    name: "money",
    beforeEnter: requireAuth,
    meta: { title: "Pul olish" },
    component: () => import("../views/Shartnoma/Money.vue"),
  },

  {
    path: "/tasks",
    name: "tasks",
    beforeEnter: requireAuth,
    meta: { title: "Topshiriqlar" },
    component: () => import("../views/Tasks/Tasks.vue"),
  },

  {
    path: "/settings",
    name: "settings",
    beforeEnter: requireAuth,
    meta: { title: "Sozlamalar" },
    component: () => import("../views/Settings/SettingMenu.vue"),
  },
  {
    path: "/diagnostic-users",
    name: "diagnostic-users",
    beforeEnter: requireAuth,
    meta: { title: "Diagnostika mijozlar" },
    component: () => import("../views/Diagnostika/Diagnostic-users.vue"),
  },
  {
    path: "/diagnostic-category",
    name: "diagnostic-category",
    beforeEnter: requireAuth,
    meta: { title: "Diagnostika bo'limlari" },
    component: () => import("../views/Diagnostika/Diagnostic-category.vue"),
  },
  {
    path: "/diagnostic",
    name: "diagnostic",
    beforeEnter: requireAuth,
    meta: { title: "Diagnostika" },
    component: () => import("../views/Diagnostika/Diagnostic.vue"),
  },
  {
    path: "/diagnostika",
    name: "diagnostika",
    beforeEnter: requireAuth,
    meta: { title: "Diagnostikalar" },
    component: () => import("../views/Diagnostika/Diagnostiklar.vue"),
  },
  {
    path: "/diagnostic-edit",
    name: "diagnostic-edit",
    beforeEnter: requireAuth,
    meta: { title: "Yakunlanganlar" },
    component: () => import("../views/Diagnostika/Diagnostic-edit-resoult.vue"),
  },
  {
    path: "/user-diagnostic",
    name: "user-diagnostic",
    beforeEnter: requireAuth,
    meta: { title: "Hodim diagnostikasi" },
    component: () => import("../views/Diagnostika/OneUserDiag.vue"),
  },
  {
    path: "/branch_setting",
    name: "branch_setting",
    beforeEnter: requireAuth,
    meta: { title: "Bo'limlar" },
    component: () => import("../views/Settings/branch_setting.vue"),
  },
  {
    path: "/service_type",
    name: "service_type",
    beforeEnter: requireAuth,
    meta: { title: "Faoliyat turlari" },
    component: () => import("../views/Settings/service_type.vue"),
  },
  {
    path: "/directions",
    name: "directions",
    beforeEnter: requireAuth,
    meta: { title: "Yo'nalishlar" },
    component: () => import("../views/Settings/directions.vue"),
  },
  {
    path: "/bizneses",
    name: "bizneses",
    beforeEnter: requireAuth,
    meta: { title: "Bizneslar" },
    component: () => import("../views/Settings/bizneses.vue"),
  },
  {
    path: "/question_settings",
    name: "question_settings",
    beforeEnter: requireAuth,
    meta: { title: "Savol sozlamasi" },
    component: () => import("../views/Settings/question-settings.vue"),
  },
  {
    path: "/all-questions",
    name: "all-questions",
    beforeEnter: requireAuth,
    meta: { title: "Barcha savollar" },
    component: () => import("../views/Settings/all-questions.vue"),
  },
  {
    path: "/question-type",
    name: "question-type",
    beforeEnter: requireAuth,
    meta: { title: "Savol turlari" },
    component: () => import("../views/Settings/question-types.vue"),
  },
  {
    path: "/question-states",
    name: "question-states",
    beforeEnter: requireAuth,
    meta: { title: "Savol holatlari" },
    component: () => import("../views/Settings/question-states.vue"),
  },
  {
    path: "/archive",
    name: "archive",
    beforeEnter: requireAuth,
    meta: { title: "Arxiv" },
    component: () => import("../views/Archive/Archive.vue"),
  },
  {
    path: "/archive-user",
    name: "archiveuser",
    beforeEnter: requireAuth,
    meta: { title: "Arxiv" },
    component: () => import("../views/Archive/UserArchive.vue"),
  },
  {
    path: "/view-result",
    name: "view-result",
    beforeEnter: requireAuth,
    meta: { title: "Natijalarni ko'rish" },
    component: () => import("../views/Diagnostika/View_result.vue"),
  },
  {
    path: "/plan",
    name: "Plan",
    beforeEnter: requireAuth,
    meta: { title: "Rejalar" },
    component: () => import("../views/Plans/Plans.vue"),
  },
];

routes = routes.filter((route) => {
  if (route.meta?.role) {
    return route.meta.role.includes(role);
  } else {
    return route;
  }
});

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: "active",
});

export default router;

