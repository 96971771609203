import axios from 'axios';
import Swal from 'sweetalert2';
import router from '../../router/index';
import store from '../../store/index';

export function clear_input_file() {
	let files = document.querySelectorAll("input[type='file']");
	files.forEach((element) => {
		element.value = '';
	});
}

export function NumbersOnly(evt) {
	evt = evt ? evt : window.event;
	var charCode = evt.which ? evt.which : evt.keyCode;
	if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
		evt.preventDefault();
	} else {
		return true;
	}
}

// export let baseURL = "http://192.168.43.97:8000/";
export let baseURL = 'https://pcg.api-crud.uz/';

export function disabled(bool) {
	document.querySelectorAll('[type="submit"]').forEach((e) => {
		e.disabled = bool;
	});
}
async function api(endpoint, method, data) {
	store.dispatch('setloading', true);
	let token = store.state.user?.access_token;
	let result = null,
		error = null;
	await axios
		.create({
			baseURL: `${baseURL}${endpoint}`,
			headers: {
				Authorization: `Bearer ${token}`,
			},
		})
		.request({ method: method.toUpperCase(), data: data })
		.then((res) => {
			result = res.data;
			clear_input_file();
		})
		.catch((err) => {
			error = err;
			catchError(err);
		})
		.finally(() => {
			store.dispatch('setloading', false);
		});
	return new Promise((resolve, reject) => {
		if (result) {
			resolve(result);
		} else {
			reject(error);
		}
	});
}

export function success(target) {
	if (target !== undefined) {
		if (typeof target == 'number') {
			document.querySelectorAll("[data-dismiss='modal']")[target].click();
		} else if (typeof target == 'string') {
			document.querySelector(`[${target}]`).click();
		}
	}
	return Swal.mixin({
		toast: true,
		position: 'top-end',
		showConfirmButton: false,
		timer: 2000,
		timerProgressBar: true,
	}).fire({
		icon: 'success',
		title: 'Amaliyot bajarildi',
	});
}

export function catchError(error) {
	if (error.response?.status == 401) {
		console.clear();
		localStorage.clear();
		Swal.mixin({
			toast: true,
			position: 'top-end',
			showConfirmButton: false,
			timer: 2000,
			timerProgressBar: true,
			didOpen: (toast) => {
				toast.addEventListener('mouseenter', Swal.stopTimer);
				toast.addEventListener('mouseleave', Swal.resumeTimer);
			},
		})
			.fire({
				icon: 'error',
				title: 'Foydalanuvchi nomi yoki parolda xatolik',
			})
			.then(() => {
				if (window.location.pathname == '/') {

				} else {
					window.location.href = '/';
				}
			});
	} else if (error.message == 'Network Error') {
		return Swal.mixin({
			toast: true,
			position: 'top-end',
			showConfirmButton: false,
			timer: 2000,
			timerProgressBar: true,
			didOpen: (toast) => {
				toast.addEventListener('mouseenter', Swal.stopTimer);
				toast.addEventListener('mouseleave', Swal.resumeTimer);
			},
		}).fire({
			icon: 'warning',
			title: "Internet bilan a'loqa mavjud emas",
		});
	} else if (error.response?.status == 422) {
		return Swal.mixin({
			toast: true,
			position: 'top-end',
			showConfirmButton: false,
			timer: 2000,
			timerProgressBar: true,
			didOpen: (toast) => {
				toast.addEventListener('mouseenter', Swal.stopTimer);
				toast.addEventListener('mouseleave', Swal.resumeTimer);
			},
		}).fire({
			icon: 'warning',
			title: "Ma'lumot to'liq emas",
		});
	} else if (error.response?.status == 404) {
		return Swal.mixin({
			toast: true,
			position: 'top-end',
			showConfirmButton: false,
			timer: 2000,
			timerProgressBar: true,
			didOpen: (toast) => {
				toast.addEventListener('mouseenter', Swal.stopTimer);
				toast.addEventListener('mouseleave', Swal.resumeTimer);
			},
		}).fire({
			icon: 'error',
			title: "URL manzil notog'ri !",
		});
	} else if (error.response?.status == 400) {
		return Swal.mixin({
			toast: true,
			position: 'top-end',
			showConfirmButton: false,
			timer: 2000,
			timerProgressBar: true,
			didOpen: (toast) => {
				toast.addEventListener('mouseenter', Swal.stopTimer);
				toast.addEventListener('mouseleave', Swal.resumeTimer);
			},
		}).fire({
			icon: 'error',
			title: error.response?.data.detail,
		});
	}
	console.clear();
}

// form data function
export const getFormData = (object) =>
	Object.entries(object).reduce((fd, [key, val]) => {
		if (Array.isArray(val)) {
			val.forEach((v) => fd.append(key, v));
		} else {
			fd.append(key, val);
		}
		return fd;
	}, new FormData());

// roles
export function roles() {
	return api(`role`, `GET`);
}

// token
export function token(data) {
	let form_data = new FormData();
	form_data.append('username', data.username);
	form_data.append('password', data.password);
	return api(`token`, `POST`, form_data);
}

export function refresh_token(data) {
	return api(`refresh-token`, `POST`, data);
}

// category
export function category(search, source_id, id) {
	const search_query = search ? `&search=${search}` : '';
	const source_query = source_id >= 0 ? `source_id=${source_id}` : '';
	const id_query = id ? `&id=${id}` : '';


	return api(`categories/?${source_query}${search_query}${id_query}`, 'GET');
}

export function add_category(data) {
	return api(`categories/create`, 'POST', data);
}

export function put_category(data) {
	return api(`categories/update`, 'PUT', data);
}

export function delete_category(id) {
	return api(`categories/delete/?id=${id}`, 'DELETE');
}

// user
export function users(p) {
	let for_archive = p.for_archive ? `&for_archive=${p.for_archive}` : ``
	let diagnostic = p.diagnostic ? `&diagnostic=${p.diagnostic}` : ``
	let contract = p.contract ? `&contract=${p.contract}` : ``
	return api(
		`users/?id=${p.id}&page=${p.page}&limit=${p.limit}&status=${p.status}${p.search ? `&search=${p.search}` : ''
		}${p.role ? `&role=${p.role}` : ''}${p.source_id ? `&source_id=${p.source_id}` : ''}${for_archive}${diagnostic}${contract}`,
		`GET`
	);
}

export function create_user(data) {
	return api(`users/create`, `POST`, data);
}

export function update_user(data) {
	return api(`users/update`, `PUT`, data);
}

export function delete_user(id) {
	return api(`users/delete/${id}`, 'DELETE');
}

// Customer
export function customers(p) {
	let for_archive = p.for_archive ? `&for_archive=${p.for_archive}` : ``
	return api(
		`customers/?id=${p.id}&page=${p.page}&limit=${p.limit}&status=${p.status}${p.search ? `&search=${p.search}` : ''
		}${for_archive}`,
		`GET`
	);
}

export function create_customer(data) {
	return api(`customers/create`, `POST`, data);
}

export function update_customer(data) {
	return api(`customers/update`, `PUT`, data);
}
// lending
export function lending_customer(p) {
	return api(`lending_customer/all?confirmed=${p.confirmed}${p.region ? `&region=${p.region}` : ``}${p.business_type ? `&business_type=${p.business_type}` : ``}${p.number_business_coach ? `&number_business_coach=${p.number_business_coach}` : ``}`, 'GET');
}
export function confirm_lending_customer(data) {
	return api(`lending_customer/confirm`, `PUT`, data);
}
export function consultation_lending_customer(data) {
	return api(`lending_customer/consultation`, `PUT`, data);
}
export function consultation_done_lending_customer(data) {
	return api(`lending_customer/consultation_done`, `PUT`, data);
}

// About Customer
export function about_customer(id) {
	return api(`mijoz/haqida/${id}`, 'GET');
}

export function add_abt_customer(data) {
	return api(`mijoz/haqida`, 'POST', data);
}

export function delete_abt_customer(id) {
	return api(`mijoz/haqida/${id}`, 'DELETE');
}

// Branch

export function branches() {
	return api('dignostika', 'GET');
}

export function add_branch(data) {
	return api(`dignostika`, 'POST', getFormData(data));
}

export function put_branch(data) {
	return api(`dignostika/${data.id}`, 'PUT', getFormData(data));
}

export function delete_branch(id) {
	return api(`dignostika/${id}`, 'DELETE');
}

// savol
export function savol() {
	return api('savol', 'GET');
}

export function add_savol(data, type) {
	return api(`savol?turi=${type}`, 'POST', data);
}

export function put_savol(id, data) {
	return api(`savol/${id}`, 'PUT', data);
}

export function delete_savol(id) {
	return api(`savol/${id}`, 'DELETE');
}

export function savolMedia(data, turi) {
	return api(`savol/media?${turi}`, 'POST', getFormData(data));
}

export function savolHideShow(id, turi) {
	return api(`savol/${id}?turi=${turi}`, 'PUT');
}
export function savolNusxa(id, data) {
	return api(`savol/${id}`, 'PUT', data);
}

// uploaded files
export function uploaded_files(id, source, page, limit, search = '') {
	return api(
		`uploaded_files/?id=${id}&source=${source}&page=${page}&limit=${limit}${search ? `&search=${search}` : ''
		}`,
		'GET'
	);
}

export function uploaded_files_create(data) {
	return api(`uploaded_files/create`, 'POST', getFormData(data));
}

export function uploaded_files_update(data) {
	return api(`uploaded_files/update`, 'PUT', getFormData(data));
}

export function uploaded_files_delete(id) {
	return api(`uploaded_files/delete?id=${id}`, 'DELETE');
}

// QUESTIONS

export function questions(
	search = '',
	id,
	category_id,
	question_type_id,
	diagnostic_id,
	page,
	limit,
	status
) {
	return api(
		`questions/?id=${id}&category_id=${category_id}&question_type_id=${question_type_id}&diagnostic_id=${diagnostic_id}&page=${page}&limit=${limit}${status == null ? `` : `&status=${status}`}${search ? `&search=${search}` : ''
		}`,
		'GET'
	);
}

export function questions_create(data) {
	return api(`questions/create`, 'POST', data);
}

export function questions_update(data) {
	return api(`questions/update`, 'PUT', data);
}
export function questions_copy(data) {
	return api(`questions/copy`, 'POST', data);
}

// question types
export function question_type(search = '', id, page, limit) {
	return api(
		`question_types/?id=${id}&page=${page}&limit=${limit}${search ? `&search=${search}` : ''
		}`,
		'GET'
	);
}

export function question_type_create(data) {
	return api(`question_types/create`, 'POST', data);
}

export function question_type_update(data) {
	return api(`question_types/update`, 'PUT', data);
}

// question state
export function question_state(search = '', id, page, limit) {
	return api(
		`question_states/?id=${id}&page=${page}&limit=${limit}${search ? `&search=${search}` : ''
		}`,
		'GET'
	);
}

export function question_state_create(data) {
	return api(`question_states/create`, 'POST', data);
}

export function question_state_update(data) {
	return api(`question_states/update`, 'PUT', data);
}

// question  options
export function question_options(search = '', id, page, limit, question_id) {
	return api(
		`question_options/?id=${id}&question_id=${question_id}&page=${page}&limit=${limit}${search ? `&search=${search}` : ''
		}`,
		'GET'
	);
}

export function question_options_create(data) {
	return api(`question_options/create`, 'POST', data);
}

export function question_options_update(data) {
	return api(`question_options/update`, 'PUT', data);
}

// question options answers
export function question_options_answers(
	search = '',
	id,
	page,
	limit,
	question_state_id,
	question_option_id,
	question_id = 0
) {
	return api(
		`question_options_answers/?id=${id}&question_state_id=${question_state_id}&question_option_id=${question_option_id}&question_id=${question_id}&page=${page}&limit=${limit}${search ? `&search=${search}` : ''
		}`,
		'GET'
	);
}

export function question_options_answers_create(data) {
	return api(`question_options_answers/create`, 'POST', data);
}

export function question_options_answers_update(data) {
	return api(`question_options_answers/update`, 'PUT', data);
}

// diagnostic
export function diagnostics(
	search = '',
	id,
	page,
	limit,
	customer_id,
	category_id,
	status,
	user_id
) {
	return api(
		`diagnostics/?id=${id}&category_id=${category_id}&customer_id=${customer_id}&page=${page}&limit=${limit}&status=${status}${search ? `&search=${search}` : ''
		}${user_id ? `&user_id=${user_id}` : ''}`,
		'GET'
	);
}

export function diagnostics_create(data) {
	return api(`diagnostics/create`, 'POST', data);
}

export function diagnostics_update(data) {
	return api(`diagnostics/update`, 'PUT', data);
}

export function diagnostic_confirm(id, data) {
	return api(`diagnostics/confirm?id=${id}`, 'POST', data);
}

export function diagnostic_users_create(data) {
	return api(`diagnostic_users/create`, 'POST', data);
}

export function diagnostic_users_get(id) {
	return api(`diagnostic_users/get?diagnostic_id=${id}`, 'get');
}
export function diagnostic_users_delete(id) {
	return api(`diagnostic_users/delete?id=${id}`, 'DELETE');
}
// contract Users
export function contract_users_create(data) {
	return api(`contract_users/create`, 'POST', data);
}
export function contract_users_get(id) {
	return api(`contract_users/get?contract_id=${id}`, 'get');
}
export function contract_users_delete(id) {
	return api(`contract_users/delete?id=${id}`, 'DELETE');
}
// diagnostic options
export function diagnostic_options(
	diagnostic_id,
	question_options_id,
	id,
	page,
	limit,
	status
) {
	return api(
		`diagnostic_options/?id=${id}&diagnostic_id=${diagnostic_id}&question_options_id=${question_options_id}&page=${page}&limit=${limit}&status=${status}`,
		'GET'
	);
}

export function diagnostic_options_create(data) {
	return api(`diagnostic_options/create`, 'POST', data);
}

export function diagnostic_options_update(data) {
	return api(`diagnostic_options/update`, 'PUT', data);
}
// shartnoma
export function contrat_all(
	p
) {
	return api(
		`contrat/all?status=${p.contracts_filter}${p.customer_id ? `&customer_id=${p.customer_id}` : ``}&${p.customer_worker_source_id ? `customer_worker_source_id=${p.customer_worker_source_id}` : ``}`,
		'GET'
	);
}

export function contrat_create(data) {
	return api(`contrat/create`, 'POST', data);
}

export function contrat_update(data) {
	return api(`contrat/update`, 'PUT', data);
}
export function contrat_update_status(data) {
	return api(`contrat/update_status`, 'PUT', data);
}
// tasks
export function task_all(p) {
	return api(
		`task/all?tasks_filter=${p.tasks_filter}&source_id=${p.source_id}${p.search ? `&search=${p.search}` : ''}&page=${p.page}&limit=${p.limit}${p.customer_worker_id ? `&customer_worker_id=${p.customer_worker_id}` : ``}&source=${p.source}`,
		'GET'
	);
}

export function task_create(data) {
	return api(`task/create`, 'POST', data);
}

export function task_update(data) {
	return api(`task/update`, 'PUT', data);
}
export function task_done(data) {
	return api(`task/done`, 'PUT', data);
}
export function task_confirmed(data) {
	return api(`task/confirmed`, 'PUT', data);
}

export function task_sent(data) {
	return api(`task/task_sent`, 'PUT', data);
}

// plans
export function plans_all(p) {
	const time = p.from_time && p.to_time ? `&from_time=${p.from_time}&to_time=${p.to_time}` : ``
	return api(
		`plans_router/all?source_id=${p.source_id}${p.search ? `&search=${p.search}` : ''}${time}`,
		'GET'
	);
}

export function plans_create(data) {
	return api(`plans_router/create`, 'POST', data);
}

export function plans_update(data) {
	return api(`plans_router/update`, 'PUT', data);
}
// expense
export function expense_all(p) {
	const time = p.from_time && p.to_time ? `&from_time=${p.from_time}&to_time=${p.to_time}` : ``
	return api(
		`expenses/get?page=${p.page}&limit=${p.limit}&source=${p.source}&source_id=${p.source_id ? p.source_id : ``}${p.search ? `&search=${p.search}` : ''}${time}`,
		'GET'
	);
}

export function expense_create(data) {
	return api(`expenses/create`, 'POST', data);
}
